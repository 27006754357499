<template>
  <div>
    <el-row ref="search_form">
      <el-col :span="24">
        <div class="search-box">
          <el-form :inline="true" size="medium" :model="searchForm">
            <el-form-item label="">
              <el-input v-model="searchForm.username" size="mini" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-input v-model="searchForm.pid" size="mini" type="number" placeholder="请输入pid"></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-select v-model="searchForm.plateId" size="small" placeholder="请选择">
                <el-option
                  v-for="item in plateList"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <el-form :inline="true" size="medium" :model="searchForm">
            <el-form-item>
              <el-button  size="mini" type="primary" @click="search">查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button  size="mini" type="primary" @click="clear">清空查询条件</el-button>
            </el-form-item>
<!--            <el-form-item>-->
<!--              <el-button  size="mini" type="primary" @click="downAll">全部下发</el-button>-->
<!--            </el-form-item>-->
<!--            <el-form-item>-->
<!--              <el-button  size="mini" type="primary" @click="refresh">更新参会人员</el-button>-->
<!--            </el-form-item>-->
          </el-form>
        </div>
      </el-col>
    </el-row>

    <el-table
      :data="tableData"
      ref="elTable"
      :header-cell-style="{backgroundColor:'#f4f4f5',textAlign:'center'}"
      :height="tableHeight"
      border
      style="width: 100%">
<!--      <el-table-column-->
<!--        type="selection"-->
<!--        align="center"-->
<!--        width="55">-->
<!--      </el-table-column>-->
      <el-table-column
        fixed
        align="center"
        prop="id"
        label="序号"
        type="index"
        width="80">
      </el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="pid"
        label="pid"
        min-width="100">
      </el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="username"
        label="姓名"
        min-width="100">
      </el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="ic"
        label="ic"
        min-width="100">
      </el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="qrcode"
        label="二维码"
        min-width="100">
      </el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="faceImgUrl"
        label="照片"
        min-width="100">
        <template scope="scope">
          <el-image
            style="width: 90px; height: 90px"
            :src="scope.row.faceImgUrl"
            :preview-src-list="[scope.row.faceImgUrl]">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="plateTitle"
        label="会议名称"
        min-width="150">
      </el-table-column>
      <!--
      <el-table-column
        fixed
        align="center"
        prop="downStatus"
        label="下发状态"
        min-width="60">
        <template scope="scope">
          <span v-if="scope.row.downStatus == 0">失败/未下发</span>
          <span v-if="scope.row.downStatus == 1">成功</span>
        </template>
      </el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="downTime"
        label="下发时间"
        min-width="100">
        <template scope="scope">
          {{fmtDate2(scope.row.downTime)}}
        </template>
      </el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="downFailReason"
        label="下发失败原因"
        min-width="150">
      </el-table-column>
      -->

      <el-table-column
        fixed
        align="center"
        prop="recogTime"
        label="扫描时间"
        min-width="100">
        <template scope="scope">
          {{fmtDate2(scope.row.recogTime)}}
        </template>
      </el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="recogType"
        label="扫描类型"
        min-width="100">
      </el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="photo"
        label="扫描照片"
        min-width="100">
        <template scope="scope">
          <el-image
            style="width: 90px; height: 90px"
            :src="scope.row.photo"
            :preview-src-list="[scope.row.photo]">
          </el-image>
        </template>
      </el-table-column>

      <el-table-column
        fixed
        align="center"
        prop="updateTime"
        label="更新时间"
        min-width="100">
        <template scope="scope">
          {{fmtDate2(scope.row.updateTime)}}
        </template>
      </el-table-column>


<!--      <el-table-column-->
<!--        align="center"-->
<!--        prop="address"-->
<!--        label="操作"-->
<!--        min-width="150">-->
<!--        <template slot-scope="scope">-->
<!--          <el-button size="mini" type="primary" @click="handleDown(scope.$index, scope.row)">下发</el-button>-->
<!--          <el-button size="mini" type="primary" @click="refreshOne(scope.$index, scope.row)">刷新信息</el-button>-->
<!--        </template>-->
<!--      </el-table-column>-->
    </el-table>
    <el-row>
      <el-col :span="24" class="pagination-box">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next"
          :page-size="pageSize"
          :page-sizes="[20, 50, 100]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import request from '@/common/utils/request.js'
import util from '@/common/utils/util.js'
import {getDeviceUserLogList} from "../../../api/device_user";
import {getPlateList, getPlateListWithGroup} from "../../../api/plate";


export default {
  name: "deviceUserLog",
  data() {
    return {
      total: 0,
      pageSize: 20,
      currentPage: 1,
      tableData: [],
      tableHeight: 200,
      editFormVisible:false,
      loading: false,
      loadingDiv: null,
      editForm: {
        id: 0,
        plateId: '',
        img: '',
        note: '',
      },
      editRules: {},
      plateList:[],

      searchForm: {
        pid: '',
        username:'',
        plateId: '',
      },

    }


  },
  watch:{

  },
  mounted() {

    this.init();

    this.getDataList(this.currentPage);

    let offsetHeight = this.$refs.search_form.$el.offsetHeight;
    if (!offsetHeight){
      offsetHeight = 0;
    }

    this.$nextTick(() => {
      this.tableHeight = document.documentElement.clientHeight - 150 - offsetHeight;
    })
    window.onresize = () => {
      this.$nextTick(() => {
          this.tableHeight = document.documentElement.clientHeight - 150 - offsetHeight;
      })
    }
  },
  methods: {
    init(){
      getPlateList().then(res=>{
        if (res.code){
          this.plateList = res.data;
          this.plateList.unshift({title: "请选择会议", id:''})
        }
      });
    },
    clear(){
      this.searchForm.pid  = '';
      this.searchForm.username  = '';

      this.getDataList(1);
    },
    fmtDate2(d2, fmt) { // yyyy-MM-dd hh:mm:ss
      if (util.isEmpty(d2)){
        return "";
      }
      return  this.fmtDate(new Date(d2*1000), fmt);
    },
    fmtDate(d, fmt){ // yyyy-MM-dd hh:mm:ss
      if(!fmt){
        fmt = "yyyy-MM-dd hh:mm:ss";
      }
      var o = {
        "M+" : d.getMonth()+1,                 //月份
        "d+" : d.getDate(),                    //日
        "h+" : d.getHours(),                   //小时
        "m+" : d.getMinutes(),                 //分
        "s+" : d.getSeconds(),                 //秒
        "q+" : Math.floor((d.getMonth()+3)/3), //季度
        "S"  : d.getMilliseconds()             //毫秒
      };
      if(/(y+)/.test(fmt)) {
        fmt=fmt.replace(RegExp.$1, (d.getFullYear()+"").substr(4 - RegExp.$1.length));
      }
      for(var k in o) {
        if(new RegExp("("+ k +")").test(fmt)){
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
        }
      }
      return fmt;
    },
    getDataList(pageNum){
      const loadingDiv = util.showLoading()

      let param = {
        pageSize: this.pageSize,
        pageNum: pageNum,
      }

      for (let key in this.searchForm) {
        param[key] = this.searchForm[key];
      }

      getDeviceUserLogList(param).then(res => {
        if(res.code == 0) {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
        }else if(res.code == 1) {
          let data = res.data;
          this.currentPage = pageNum;
          this.total = data.total;
          this.tableData = data.dataList;
        }
        loadingDiv.close();
      }).catch(res => {
        loadingDiv.close();
      })
    },
    search() {
      this.getDataList(1)
    },
    handleCurrentChange(pageNum){
      this.getDataList(pageNum);
    },
    handleSizeChange(pageSize){
      this.pageSize = pageSize;
      this.getDataList(1)
    }
  }
}
</script>

<style scoped lang="scss">

  // @import "~@/common/styles/element-variables.scss";
  .search-box {
    background-color: #FFFFFF;
    min-height: 36px;
    line-height: 36px;
    padding-top: 2px;
    padding-bottom: 6px;
    .el-form-item {
      margin-bottom: 0px !important;
    }
    .el-form--inline {
      display: inline-block;
    }
  }
  .pagination-box {
    text-align: left;
    margin-top: 10px;
  }
</style>
<style lang="scss">
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .el-dialog__header {

  }
  .el-dialog__body {
    padding-top: 10px;
    padding-bottom: 0px;
  }
  .el-dialog__footer {
    padding-top: 0px;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .el-tabs--border-card {
    box-shadow: none;
  }
</style>
